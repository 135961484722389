.tablecelltext{
    max-width: 7.5vw ;
    max-height: 100px;
    overflow-x: scroll;
    cursor: pointer;
    text-align: center;
}

.selectIcon {
    fill: white;
}


.selectRoot {
    color: white;
}